document.addEventListener("livewire:load", function () {
    Livewire.on("showSetPasswordPopup", (home_route) => {
        new bootstrap.Modal(document.getElementById("change-password")).show();

        var setPasswordModal = document.getElementById("change-password");
        setPasswordModal.addEventListener("hide.bs.modal", function (event) {
            location.href = home_route;
        });
    });
});
